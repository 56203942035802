import React from 'react'
import {Link } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/layout/layout'
import { Subline, Headline, StyledButton } from '../components/layout/layoutComponents'
import { typo, colors } from '../components/utils'
import leftpic from './orangeRing.png'
import rightpic from './blackRing.png'
import TeamMember from '../components/team/teamMember'
import CasesSliderEN from '../components/cases/casesSliderEN'

const Hero = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  width: 100%;
  height: 360px;
  @media (min-width: 720px) {
  height: 535px;
  }
  @media (min-width: 1024px) {
  height: 835px;
  }
`

const HeroHeader = styled.h1`
  margin: 0;
  margin-bottom: 75px;
  align-self: flex-end;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 6px;
  color: #fff;
  text-transform: uppercase;
  @media (min-width: 516px) {
    font-size: 40px;
    line-height: 52px;
  }
  @media (min-width: 688px) {
    font-size: 70px;
    line-height: 84px;
  }
  @media (min-width: 1024px) {
    max-width: 1088px;
    font-size: 80px;
    line-height: 92px;
  }
  @media (min-width: 1224px) {
    max-width: 1088px;
    font-size: 100px;
    line-height: 132px;
  }
`
const HeroInner = styled.div`
  display: flex;
  width: 1140px;
  height: 100%;
  padding: 0 35px;
`

const StyledArticle = styled.article`
  position: relative;
  max-width: 1140px;
  margin: 0 auto;
  padding: 50px 35px;
  font-size: 20px;
  @media (min-width: 720px) {
    padding: 100px 35px 30px;
  }
`
const ArticleText = styled.div`
  margin-top: 45px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (min-width: 720px) {
    margin-top: 90px;
  }
  h5 strong {
    margin: 0;
    padding: 0;
    color: ${colors.orange};
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: 1.2px;
  }
  p {
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 1.2px;
    color: ${colors.default};
    margin: 0;
    margin-bottom: 30px;
  }
  ul {
    display: flex;
    flex-wrap:wrap;
    font-size: ${typo.fontdefault};
    font-weight: ${typo.regular};
    line-height: ${typo.default};
    color: ${colors.default};
    list-style: none;
    li {
      min-width: 40%;
      position: relative;
      margin-bottom: 15px;
      &:nth-child(1) {
      margin-right: 190px;
      }
      &:nth-child(3) {
      margin-right: 190px;
      }
    }  
    li:before{
      content: '';
      position: absolute;
      top: 8px;
      left: -39px;
      height: 13px;
      width: 13px;
      background-color: ${colors.orange};
    }
  }  
`
const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  @media(min-width: 1024px) {
    max-width: 45%;
  }
`
const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  @media(min-width: 1024px) {
    max-width: 45%;
  }
`
const Results = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  ul {
    display: flex;
    flex-wrap:wrap;
    font-size: ${typo.fontdefault};
    font-weight: ${typo.regular};
    line-height: ${typo.default};
    color: ${colors.default};
    list-style: none;
    li {
      max-width: 40%;
      position: relative;
      margin-bottom: 15px;
      &:before{
        content: '';
        position: absolute;
        top: 8px;
        left: -39px;
        height: 13px;
        width: 13px;
        background-color: ${colors.orange};
      }
      @media (min-width: 720px) {
        &:nth-child(1) {
        margin-right: 168px;
        }
        &:nth-child(3) {
        margin-right: 168px;
        }
      }
    }  
  }
`

const BlackRing = styled.div`
  display: none;
  @media (min-width: 1298px) {
    display: block;
    position: absolute;
    bottom: 90px;
    right: -285px;
    width: 430px;
    height: 430px;
    background: url(${rightpic});
    background-size: cover;
  }
  @media (min-width: 1410px) {
    width: 500px;
    height: 500px;
  }
`
const OrangeRing = styled.div`
  display: none;
  @media (min-width: 1298px) {
    display: block;
    position: absolute;
    top: 790px;
    left: -285px;
    width: 530px;
    height: 530px;
    background: url(${leftpic});
    background-size: cover;
  }
  @media (min-width: 1410px) {
    width: 600px;
    height: 600px;
  }
`  
const TeamSection = styled.div`
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  padding: 0 0 55px 15px;
  display: flex;
  justify-content: space-between;
`
const MemberContainer  = styled.div`
  max-width: 400px;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  img {
    max-width: 400px;
    width: auto;
  }
`


export default function CasesTemplate ({ pageContext: { data }}) {
  const contentLeft = data.articleLeft
  const contentRight = data.articleRight
  const contentResults = data.results
  return (
    <Layout>
    <div style={{position: `relative`, overflowX: `hidden`}}>
    <Hero style={{backgroundImage: `url(${data.image[0]?.url})`}}>
      <HeroInner>
        <HeroHeader>{data.header}</HeroHeader>
      </HeroInner>
    </Hero>
      <StyledArticle>
          <Subline style={{marginBottom: `0`}}>{data.textSubheader}</Subline>
          <Headline style={{color: `#3C3C3B`}}>{data.textHeader}</Headline>
        <ArticleText>
          <LeftSide dangerouslySetInnerHTML={{ __html: contentLeft }} />
          <RightSide dangerouslySetInnerHTML={{ __html: contentRight }} />
          <Results dangerouslySetInnerHTML={{ __html: contentResults }} />
        <p style={{marginTop: `45px`}}><strong>{data.ctaText}</strong></p>
        </ArticleText>
      </StyledArticle>
      <TeamSection>
        {data.team.map(member => (
        <MemberContainer>
            <TeamMember
            src={member.image[0].url}
            intro={member.teamIntro}
            alt={member.teamame}
            name={member.teamame}
            role={member.teamRole}
            mail={member.email}
            phone={member.phoneNumber}
          />
        <StyledButton style={{maxWidth: `130px`}}><Link to="/contact">Contact</Link></StyledButton>
        </MemberContainer>
          ))}
      </TeamSection>
        <OrangeRing />
        <BlackRing />
    </div>
      <CasesSliderEN />
  </Layout>
  )
} 
