import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import { Container, Subline } from '../layout/layoutComponents'
import { NextArrow, PrevArrow } from '../slider/slider'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import styled from 'styled-components'

const SliderSection = styled.section`
  background-color: rgba(156, 158, 159, 0.2);
  padding-bottom: 60px;
  width: 100%;
`
const SlideContainer = styled.div`
  position: relative;
  display: flex;
  margin: 0 auto;
  padding: 0 35px;
  height: 170px;
  width: 260px;
  @media(min-width: 540px) {
    height: 270px;
    width: 360px;
  }
`
const SliderTitle = styled.h4`
  font-size: 28px;
  line-height: 34px;
  margin: 0 auto;
  text-align: center;
  color: #F28E00;
  padding: 40px 0;
`

const SliderInner = styled.div`
  position: relative;
  height: 165px;
  width: 250px;
  max-width: 95%;
  max-height: 95%;
  margin: 0 auto;
  margin-left: -15px;
  @media(min-width: 360px) {
    margin: 0 auto;
  }
  @media(min-width: 540px) {
    height: 270px;
    width: 360px;
    margin: 0 auto;
  }
`

const SlideOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 6000;
  opacity: 0;
  padding: 15px 35px;
  background-color: rgba(242,142,0,0.7);
  &:hover {
    background-color: rgba(242,142,0,0.7);
    opacity: 1;
    cursor: pointer;
  }
  h6 {
    font-size: 16px;
    margin: 0;
  }
  p {
    color: #fff;
    font-size: 16px;
    line-height: 18px;
  }  
`    
const SlideLink = styled(Link)`
  text-decoration: none;
  color: #fff;
`

const SlideImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repaet;
`

const settings = {
  autoplay: false,
  infinite: true,
  arrows: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 1480,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 3
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1
      }
    },
    {
      breakpoint: 650,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 2
      }
    }
  ]
};


function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <NextArrow role="arrow"
      className={className}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <PrevArrow role="arrow"
      className={className}
      onClick={onClick}
    /> 
  );
}


const CasesSlide = () => (
  <StaticQuery
    query={graphql`
    query MyQuery {
      craftHomepageHomepageEntry {
        cases {
          ... on Craft_cases_cases_Entry {
            id
            image {
              url
            }
            title
            slug
            excerpt
          }
        }
      }
    }
  `}

    render={({craftHomepageHomepageEntry}) => (
    <SliderSection>
      <Subline style={{margin: `0 auto`, textAlign: `center`, padding: `40px 0`}}>MORE CASES</Subline>
      <Container>
        <Slider {...settings}>
          {craftHomepageHomepageEntry.cases.map(item => (
            <SlideContainer id={item.id}>
              <SliderInner>
                <SlideOverlay>
                    <SlideLink to={`/${item.slug}`}>
                      <h6>{item.title}</h6>
                      <p>{item.excerpt}</p>
                    </SlideLink>
                </SlideOverlay>
              <SlideImage style={{backgroundImage: `url(${item.image[0].url})`}} />
              </SliderInner>
            </SlideContainer>
          ))}
        </Slider>  
      </Container>
    </SliderSection>   
    )}  
  />
);

export default CasesSlide;
